import React, { Suspense, useLayoutEffect } from "react";
import { BrowserRouter as Router, Route, withRouter } from "react-router-dom";
import Preloader from "./components/layouts/Preloader";

const Home = React.lazy(() => import("./components/pages/Homefour"));
const AboutUs = React.lazy(() => import("./components/pages/AboutUs"));
const Login = React.lazy(() => import("./components/pages/Login"));
const Register = React.lazy(() => import("./components/pages/Register"));
const ForgotPassword = React.lazy(() => import("./components/pages/ForgotPassword"));
const ResetPassword = React.lazy(() => import("./components/pages/ResetPassword"));
const OtpVerification = React.lazy(() => import("./components/pages/OtpVerification"));
const UserProfile = React.lazy(() => import("./components/pages/UserProfile"));
const Facilities = React.lazy(() => import("./components/pages/Facilities"));
const BasketballCourt = React.lazy(() => import("./components/pages/BasketballCourt"));
const ShootingMachines = React.lazy(() => import("./components/pages/ShootingMachines"));
const WeightRoom = React.lazy(() => import("./components/pages/WeightRoom"));
const KidsTrainingArea = React.lazy(() => import("./components/pages/KidsTrainingArea"));
const Events = React.lazy(() => import("./components/pages/Events"));
const Thanks = React.lazy(() => import("./components/pages/Thanks"));
const Contacts = React.lazy(() => import("./components/pages/Contacts"));
const Booking = React.lazy(() => import("./components/pages/Booking"));
const Privacy = React.lazy(() => import("./components/pages/Privacy"));
const Terms = React.lazy(() => import("./components/pages/Terms"));

const ScrollToTop = withRouter(({ children, location: { pathname } }) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children || null;
});

if (process.env.NODE_ENV === 'debug') {
  console.log("debug")
}

function App() {
  return (
    <Router>
      <Suspense fallback={<Preloader />}>
        <ScrollToTop>
          <Route exact path="/" component={Home} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route exact path="/otp-verification" component={OtpVerification} />
          <Route exact path="/user-profile" component={UserProfile} />
          <Route path="/aboutus" component={AboutUs} />
          <Route path="/facilities" component={Facilities} />
          <Route path="/basketball-courts" component={BasketballCourt} />
          <Route path="/shooting-machines" component={ShootingMachines} />
          <Route path="/weight-room" component={WeightRoom} />
          <Route path="/kids-training-area" component={KidsTrainingArea} />
          <Route path="/events" component={Events} />
          <Route path="/thank-you" component={Thanks} />
          <Route path="/contactus" component={Contacts} />
          <Route path="/booking" component={Booking} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/terms" component={Terms} />
        </ScrollToTop>
      </Suspense>
    </Router>
  );
}

export default App;
